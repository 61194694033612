<template>
  <div>
    <transition name="fade">
      <!-- <div v-if="isSurveyCompleted" class="background pt-5">
        <b-container class="text-center pt-5 text-white">
          <b-row align-h="center" class="pt-5">
            <b-col cols="5" class="pt-5">
              <h1 class="font-weight-bold mb-5">
                {{ $t("page.done.thankYouMessage") }}
              </h1>
              <h1>{{ $t("page.done.redirectMessage") }}</h1>
            </b-col>
          </b-row>
          </b-container
        >
      </div> -->
      <div>

      </div>
    </transition>
    <base-loader-modal />
  </div>
</template>

<script>
import BaseLoaderModal from "./../components/BaseLoaderModal";
export default {
  components: {
    BaseLoaderModal
  },
  watch: {
    isSurveyCompleted: {
      handler: function() {
        if (this.isSurveyCompleted) {
          // this.$store.dispatch("loader/close");
          window.removeEventListener("beforeunload",this.handleBeforeUnload)
          window.location = this.completeLink;
          // setTimeout(() => {
          //   window.location = this.completeLink;
          // }, 1500);
        } else
          this.$store.commit("loader/SET_LOADER", {
            messageText: "page.done.loadingMessage"
          });
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener("beforeunload",this.handleBeforeUnload)
  },
  beforeDestroy(){
    window.removeEventListener("beforeunload",this.handleBeforeUnload)
  },
  methods:{
    handleBeforeUnload(event){
      event.preventDefault();
      const confirmationMessage="Are you sure you want to leave?\nYour participation will not count if you leave now!"
      event.returnValue = confirmationMessage; // some browsers require returnValue to be set
      return confirmationMessage;
    }
  },
  computed: {
    completeLink() {
      return this.$store.getters["stimulus/getCompleteLink"];
    },
    isSurveyCompleted() {
      return this.isUploadImagesCompleted && this.isUploadAnswersCompleted;
    },
    isUploadImagesCompleted() {
      return this.$store.getters["stimulus/isUploadImagesCompleted"];
    },
    isUploadAnswersCompleted() {
      return this.$store.getters["stimulus/isUploadAnswersCompleted"];
    }
  }
};
</script>

<style scoped>
.background {
  background-color: var(--primary);
  height: calc(100vh - 70px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
